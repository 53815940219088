import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-form-multi-select',
  templateUrl: './form-multi-select.component.html',
  styleUrls: ['./form-multi-select.component.scss']
})
export class FormMultiSelectComponent implements OnInit {

  selectedVals = [];

  @Input() tituloMultiSelect: String;
  @Input() titulo: String;
  @Input() maxItems: number;
  @Input() data = [];
  @Input() disabled: boolean = false;

  @Input() get selectedValues() {
    return this.selectedVals;
  } set selectedValues(selectedVals) {
    if (this.selectedVals !== selectedVals) {
      this.selectedVals = selectedVals;
      setTimeout(() => {
        this.selectedValuesChange.emit(this.selectedVals);
      }, 10);
    }
  }
  @Output() selectedValuesChange = new EventEmitter();

  dropdownSettings: IDropdownSettings = {};

  constructor(
    private i18n:I18n
  ) {}

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: this.i18n('Seleccionar todos'),
      unSelectAllText: this.i18n('Deseleccionar todos'),
      itemsShowLimit: this.maxItems,
      allowSearchFilter: true,
      searchPlaceholderText: this.i18n('Buscar'),
      noDataAvailablePlaceholderText: this.i18n('No hay datos disponibles')
    };
  }

  onItemSelect($event: any) {

  }

  onSelectAll($event: any) {

  }
  getItemsSelected():any{
    return this.selectedVals;
  }

}
