import { OnInit, EventEmitter } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
var FormMultiSelectComponent = /** @class */ (function () {
    function FormMultiSelectComponent(i18n) {
        this.i18n = i18n;
        this.selectedVals = [];
        this.data = [];
        this.disabled = false;
        this.selectedValuesChange = new EventEmitter();
        this.dropdownSettings = {};
    }
    Object.defineProperty(FormMultiSelectComponent.prototype, "selectedValues", {
        get: function () {
            return this.selectedVals;
        },
        set: function (selectedVals) {
            var _this = this;
            if (this.selectedVals !== selectedVals) {
                this.selectedVals = selectedVals;
                setTimeout(function () {
                    _this.selectedValuesChange.emit(_this.selectedVals);
                }, 10);
            }
        },
        enumerable: true,
        configurable: true
    });
    FormMultiSelectComponent.prototype.ngOnInit = function () {
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: this.i18n('Seleccionar todos'),
            unSelectAllText: this.i18n('Deseleccionar todos'),
            itemsShowLimit: this.maxItems,
            allowSearchFilter: true,
            searchPlaceholderText: this.i18n('Buscar'),
            noDataAvailablePlaceholderText: this.i18n('No hay datos disponibles')
        };
    };
    FormMultiSelectComponent.prototype.onItemSelect = function ($event) {
    };
    FormMultiSelectComponent.prototype.onSelectAll = function ($event) {
    };
    FormMultiSelectComponent.prototype.getItemsSelected = function () {
        return this.selectedVals;
    };
    return FormMultiSelectComponent;
}());
export { FormMultiSelectComponent };
