<div class="top"></div>
<div class="row justify-content-between">
  <div class="col">
    <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div>
  <div class="col">
    <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
    <!-- Codi Column -->
    <ng-container matColumnDef="codi">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
      <mat-cell *matCellDef="let row"><a (click)="edit(row)" class="is-link cursor detalle">{{row.id}}</a></mat-cell>
    </ng-container>

    <!-- descripcio Column -->
    <ng-container matColumnDef="descripcio">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripció</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.descripcio}}</mat-cell>
    </ng-container>

    <!-- Hora inici Column -->
    <ng-container matColumnDef="horaInici">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora d'inici</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.horaInici }}</mat-cell>
    </ng-container>

    <!-- Hora fi Column -->
    <ng-container matColumnDef="horaFi">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora fi</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.horaFi }}</mat-cell>
    </ng-container>

    <!-- esTransit Column -->
    <ng-container matColumnDef="esTransit">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Trànsit</mat-header-cell>
      <mat-cell *matCellDef="let row"><mat-checkbox [checked] = "row.esTransit" [disabled] = 'true'></mat-checkbox></mat-cell>
    </ng-container>

    <!-- Aparells column -->
    <ng-container matColumnDef="aparells">
      <mat-header-cell *matHeaderCellDef i18n>
        Càmera Inici-Càmera Destí / Aparells
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.esTransit; else aparellsContent">
          {{ row.cameraInici.descripcion }} - {{ row.cameraDesti.descripcion }}
        </ng-container>
        <ng-template #aparellsContent>
          <a (click)="showAparells(row)" i18n>
            Aparells: {{row.aparells.length}} <i [class]="'far fa-file-alt'" style="color: mediumslateblue;" [matTooltip]="showAparells(row.aparells)"
              matTooltipClass="line-broken-tooltip"></i>
          </a>
        </ng-template>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>
