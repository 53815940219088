<div class="form-group" style="background-color: white; margin: 2vh;">
    <div style="display: flex; justify-content: space-around; align-items: center; margin-bottom: 1vh;">
        <span style="margin-top: 8px;">
            {{this.title}}
        </span>
        <span >
          <button type="submit" mat-raised-button color="warn" style="margin-top: 6px;" (click)="reset()" i18n>Reset</button>
        </span>
    </div>
    <div style="display: flex; justify-content: space-around;">
        <mat-form-field style="width: 30%;">
            <input matInput [owlDateTimeTrigger]="dt12" [owlDateTime]="dt12" [(ngModel)]= "this.val" (ngModelChange)="this.val=$event" 
            [selectMode]="'rangeFrom'">
            <mat-placeholder class="calendar-placeholder">{{this.label1}}</mat-placeholder>
            <mat-icon matSuffix [owlDateTimeTrigger]="dt12" class="date-picker-trigger"><i class="fas fa-clock"></i></mat-icon>
            <owl-date-time #dt12 [pickerType]="this.type"></owl-date-time>
        </mat-form-field>
        <mat-form-field style="width: 30%;">
            <input matInput [owlDateTimeTrigger]="dt13" [owlDateTime]="dt13" [(ngModel)]= "this.val" (ngModelChange)="this.val=$event" 
            [selectMode]="'rangeTo'">
            <mat-placeholder class="calendar-placeholder">{{this.label2}}</mat-placeholder>
            <mat-icon matSuffix [owlDateTimeTrigger]="dt13" class="date-picker-trigger"><i class="fas fa-clock"></i></mat-icon>
            <owl-date-time #dt13 [pickerType]="this.type"></owl-date-time>
        </mat-form-field>
    </div>
</div>
