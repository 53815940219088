import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { AparellFilter } from '../../models/aparell-filter';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { AparellTipusService } from 'app/sections/configuracio/aparells/services/aparellTipus.service';
import { AparellService } from '../../services/aparells-service.service';
import { PropostesService } from 'path-propostes-services/propostes.service';
import { LocalizacionService } from '../../services/localizacion.service';
import { AparellsTableComponent } from '../../components/aparells-table/aparells-table.component';
import { AparellsEditorComponent } from '../../components/aparells-editor/aparells-editor.component';
import { AparellsFilterComponent } from '../../components/aparells-filter/aparells-filter.component';

@Component({
  selector: 'app-aparells-llista',
  templateUrl: './aparells-llista.component.html',
  styleUrls: ['./aparells-llista.component.scss']
})
export class AparellsLlistaComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: AparellFilter;
  somIdFoo: number;
  tipusMatricules = Array();
  aparellTipus = [];
  tipusRadar = [];
  localizacionPropuestas = [];
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(AparellsTableComponent) aparellsTable: AparellsTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    private aparellService: AparellService,
    private aparellTipusService: AparellTipusService,
    private propostesService: PropostesService,
    private localizacionService: LocalizacionService,
    public thisDialogRef: MatDialogRef<AparellsLlistaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new AparellFilter('', '');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.Aparells);
    this.aparellsTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.aparellsTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });

    this.aparellTipus.push({'id': '', 'descTipus': ''});
    this.aparellTipusService.getAll().subscribe(result => {
      if (result !== null) {
        result.forEach(element => {
          this.aparellTipus.push({'id': element.id, 'descTipus': element.descTipus});
        });
      }
    });

    this.tipusRadar.push({'id': '', 'descTipus': ''});
    this.aparellService.getAparellTipusRadar().subscribe(result => {
      if (result !== null) {
        result.forEach(element => {
          this.tipusRadar.push({'id': element.id, 'descTipus': element.descTipus});
        });
      }
    });
    this.localizacionPropuestas.push({'id': '', 'descTipus': ''});
    this.localizacionService.getLocalizacionPropuestas().subscribe(result => {
      if (result !== null) {
        result.forEach(element => {

          this.localizacionPropuestas.push({'id': element.m_Item1, 'descTipus': element.m_Item2});
        });
      }
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Aparells'));
    }
  }

  edit() {
    const idSeleccionat: string = this.aparellsTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);
    data.tag = {'aparellTipus': this.aparellTipus, 'tipusRadar': this.tipusRadar, 'localizacionPropuestas' : this.localizacionPropuestas};
    const ref = this.dialog.open(AparellsEditorComponent, {
      width: '70%',
      minHeight: '40%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data,
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.aparellsTable.load(); }
    });
  }

  refresh() {
    this.aparellsTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(AparellsFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.aparellsTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: AparellFilter) => {
      this.aparellsTable.filter.set(filter);
      this.aparellsTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  generateExcel() {
    this.aparellsTable.generateExcel();
  }
}
