import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { AparellsLlistaComponent } from '../aparells-llista/aparells-llista.component';
import { Builder } from 'path-shared/models/builder';
import { AparellsConjuntsTableComponent } from '../../components/aparells-conjunts-table/aparells-conjunts-table.component';
import { ConjuntAparellsFilter } from '../../models/conjunt-aparells.filter';
import { ConjuntsAparellsFilterComponent } from '../../components/conjunts-aparells-filter/conjunts-aparells-filter.component';
import { ConjuntsAparellsEditorComponent } from '../../components/conjunts-aparells-editor/conjunts-aparells-editor.component';

@Component({
  selector: 'app-aparells-conjunts',
  templateUrl: './aparells-conjunts.component.html',
  styleUrls: ['./aparells-conjunts.component.scss']
})
export class AparellsConjuntsComponent implements OnInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: ConjuntAparellsFilter;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(AparellsConjuntsTableComponent) conjuntsTable: AparellsConjuntsTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<AparellsLlistaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new ConjuntAparellsFilter('', '');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.Aparells);
    this.conjuntsTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });

  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Conjunts d\'aparells'));
    }
  }

  refresh() {
    this.conjuntsTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(ConjuntsAparellsFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.conjuntsTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: ConjuntAparellsFilter) => {
      this.conjuntsTable.filter.set(filter);
      this.conjuntsTable.load();
    });
  }

  create() {
    const data: ComponentDialog = Builder.newComponentDialog(true, false);
    const ref = this.dialog.open(ConjuntsAparellsEditorComponent, {
      width: '70%',
      minHeight: '40%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {conjunt: null, componentDialog: data},
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.conjuntsTable.load(); }
    });
  }

  onRowSelected(somid) {
  }

  generateExcel() {
    this.conjuntsTable.generateExcel();
  }

}
