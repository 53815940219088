var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";
import { HttpParams } from "@angular/common/http";
import { Reflection } from "path-shared/services/reflection/reflection";
var defaultSortParam = 'codi';
var defaultSortDirection = 'asc';
var ConjuntsAparellsFilterService = /** @class */ (function (_super) {
    __extends(ConjuntsAparellsFilterService, _super);
    function ConjuntsAparellsFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConjuntsAparellsFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('id', filter.id)
            .append('descripcio', filter.descripcio)
            .append('horaInici', Reflection.empty(filter.horaInici) ? null : filter.horaInici.toDateString())
            .append('horaFi', Reflection.empty(filter.horaFi) ? null : filter.horaFi.toDateString())
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return ConjuntsAparellsFilterService;
}(CustomFilterService));
export { ConjuntsAparellsFilterService };
