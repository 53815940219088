import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatTabChangeEvent } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { I18n } from "@ngx-translate/i18n-polyfill";
import { ToastrService } from "ngx-toastr";
import { CFG_PAISES } from "path-models/CFG_PAISES";
import { CFG_TIPOPROPUESTA } from "path-models/CFG_TIPOPROPUESTA";
import { PropostesDataSource } from "path-propostes-services/propostes.datasource";
import { PropostesService } from "path-propostes-services/propostes.service";
import { SingleEditDialogData } from "path-shared/components/dialogs/single-edit-dialog/single-edit-dialog-data.model";
import { SingleEditDialogComponent } from "path-shared/components/dialogs/single-edit-dialog/single-edit-dialog.component";
import { ChipListElement } from "path-shared/components/form/chips-list-input/chip-list-element";
import { ChipsListInputComponent } from "path-shared/components/form/chips-list-input/chips-list-input.component";
import { KeyCounter } from "path-shared/models/keycounter";
import { PaginationFilter } from "path-shared/models/pagination.filter";
import { Parametros } from "path-shared/models/parametros";
import { AuthorizationService } from "path-shared/services/authorization/authorization-service";
import { HttpSecureClient } from "path-shared/services/http/httpSecureClient";
import { ImagesService } from "path-shared/services/images/images.service";
import { NotificationService } from "path-shared/services/notification/notification.service";
import { ParametersService } from "path-shared/services/parameters/parameters.service";
import { Reflection } from "path-shared/services/reflection/reflection";
import { StartupService } from "path-shared/services/startup/startup.service";
import { ValidationService } from "path-shared/services/validation/validation.service";
import { BehaviorSubject } from "rxjs";
import { PropostaEstats } from "../../models/proposta-estats.model";
import { PropostesPendentsCount } from "../../models/proposta-pendents-count.model";
import { PropostaFilter } from "../../models/proposta.filter";
import { Proposta } from "../../models/proposta.model";
import { PropostaViewerComponent } from "../../components/propostes-classificar/proposta-viewer/proposta-viewer.component";

@Component({
  selector: 'app-propostes-page',
  templateUrl: './propostes-page.component.html'
})

export class PropostesPageComponent implements OnInit {
  CONST_ID_ALL = '-999';

  selectedAll = false;
  data: Proposta[] = [];
  dataSource: PropostesDataSource;
  filter: PropostaFilter = new PropostaFilter('Data', 'asc');
  filterPage: PaginationFilter;
  current: number;
  start: number;
  tipusPropostes: CFG_TIPOPROPUESTA[];
  currentProposta: Proposta;
  currentPage: number;
  counters: KeyCounter[];
  tipusCounters: KeyCounter[];
  totalPropostes: number;
  pageTitle: string;
  propostesPendentsAnterior = -1;
  novesPropostes = false;
  validacioError = [];
  paisosCounters: KeyCounter[];
  private paisos: CFG_PAISES[];
  agruparPerPais = false;
  timeout = null;
  private pageSize: number;
  imagesLoaded: Promise<boolean>;
  ordre = 'desc';

  tiposValidosSource: BehaviorSubject<ChipListElement[]>;
  paisesValidosSource: BehaviorSubject<ChipListElement[]>;

  @ViewChild('propostaViewer') propostaViewer: PropostaViewerComponent;
  @ViewChild('tipoPropuestaCombo') tipoPropuestaCombo: ChipsListInputComponent;
  @ViewChild('paisCombo') paisCombo: ChipsListInputComponent;

  constructor(
    private http: HttpSecureClient,
    private route: ActivatedRoute,
    private router: Router,
    private propostesService: PropostesService,
    private toastr: ToastrService,
    private imagesService: ImagesService,
    private i18n: I18n,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private notif: NotificationService,
    private validation: ValidationService,
    private startupService: StartupService,
    private parametersService: ParametersService,
    private not: NotificationService
  ) {
    this.filter.proposta = this.route.snapshot.params.id;
    this.tiposValidosSource = new BehaviorSubject(undefined);
    this.paisesValidosSource = new BehaviorSubject(undefined);
    this.agruparPerPais = this.parametersService.getParameter(Parametros.AGRUPAR_PROPUESTAS_PAIS) === 'Y';
    this.current = 0;
    this.totalPropostes = 0;
    this.currentPage = 0;
    this.counters = KeyCounter.create(['0', '1', '2']);
    this.tipusCounters = KeyCounter.create([]);
    this.paisosCounters = KeyCounter.create([]);
    this.pageSize = 1000;

    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

   ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.Propostes);
    if (this.auth.UserCanSeePage(this.auth.Pages.Prepropostes) && !this.auth.IsSuperUser()) {
      this.filter.proposta = '4';
    }
    this.dataSource = new PropostesDataSource(this.http);
    this.dataSource.countChange.subscribe(count => { this.totalPropostes = count; });

    if (this.agruparPerPais) {
      this.paisos = [];
      this.startupService.GetPaisos().forEach(pais => {
        if (pais.PAIACTIVO) {
          this.paisos.push(pais);
          if (this.filter.pais === null) {
            this.filter.pais = pais.SIGLA;
           // this.paisCombo.selectManually(this.filter.pais);
          }
          this.paisosCounters.push(new KeyCounter(pais.SIGLA));
        }
      });
      // this.paisosCounters.push(new KeyCounter(''));
      const paisesValidos: ChipListElement[] = this.getPaisesValidos();
      this.paisesValidosSource.next(paisesValidos);
    }

    this.propostesService.getTipusPropostes().subscribe(result => {
      this.tipusPropostes = result;
      this.tipusPropostes.forEach(tipus => {
        this.tipusCounters.push(new KeyCounter(tipus.ID.toString()));
      });
      this.tiposValidosSource.next(this.getTiposValidos());

      const dataSubscription = this.dataSource.connect().subscribe(
        propostes => {
          this.data = propostes;

          if (this.data.length > 0) {
            this.changeCurrent();
          } else {
            this.currentProposta = null;
          }
        },
        errormsg => {
          console.log('Error Getting Location: ', errormsg);
        }
      );

      // this.route.params.subscribe(params => {
      //   this.filter.proposta = params.id;
      // });

      this.notif.onPropostesPendentsChange.subscribe((value: PropostesPendentsCount) => {
        let processedValue = -1;
        if (this.auth.UserCanSeePage(this.auth.Pages.Prepropostes) && !this.auth.IsSuperUser()) {
          processedValue = value.prepropostes;
        } else {
          processedValue = value.propostes;
        }
        if (this.propostesPendentsAnterior === -1) {
          this.propostesPendentsAnterior = processedValue;
        } else {
          if (processedValue !== this.propostesPendentsAnterior) {
            if (processedValue > this.propostesPendentsAnterior) {
              this.novesPropostes = true;
              this.toastr.info(this.i18n('S\'han detectat noves propostes. És necessari actualitzar'));
            }
            this.propostesPendentsAnterior = processedValue;
          }
        }
      });
      this.update(true);
    });
  }

  ngAfterViewInit(): void {
    this.not.titleChange(this.i18n('Propostes'));
  }

  update(init) {
    if (init) {
      this.current = 0;
      this.currentPage = 0;
    }
    this.getPaisosCounters();
    this.getTipoPropostaCounters();
    if (this.filter.proposta === PropostaEstats.ACCEPTADA.toString()) {
      this.pageTitle = this.i18n('Propostes acceptades');
    } else if (this.filter.proposta ===  PropostaEstats.PENDENT.toString()) {
      this.pageTitle = this.i18n('Propostes pendents');
    } else if (this.filter.proposta === PropostaEstats.DESESTIMADA.toString()) {
      this.pageTitle = this.i18n('Propostes rebutjades');
    }
    this.novesPropostes = false;
  }

  loadData() {
    this.dataSource.loadPropostes(
      this.filter,
      this.currentPage,
      this.pageSize,
      'Data',
      this.ordre
    );
  }

  previousProposta() {
    if (this.totalPropostes > 0) {
      if (this.current > 0) {
        this.current--;
      } else {
        if (this.currentPage > 0) {
          this.current = this.pageSize - 1;
          this.currentPage--;
          this.update(false);
        } else {
          this.current = 0;
        }
      }
      if (this.data.length > 0) {
        this.changeCurrent();
      }
    }
  }

  changeType(event: MatTabChangeEvent) {
    console.log(event);
  }

  nextProposta() {
    if (this.current < this.pageSize - 1) {
      if ( this.currentPage * this.pageSize + this.current < this.totalPropostes - 1 ) {
        this.current++;
      }
    } else {
      if ((this.currentPage + 1) * this.pageSize <= this.totalPropostes) {
        this.current = 0;
        this.currentPage++;
        this.update(false);
      }
    }
    if (this.data.length > 0) {
      this.changeCurrent();
    }
  }

  changeCurrent() {
    this.currentProposta = this.data[this.current];
    if (this.currentProposta.IMATGES == null) {
      this.propostesService
        .getImagesByNumDenInstit(this.currentProposta.NUMDENUNCIA)
        .subscribe(images => {
          // Per evitar assignar un array buit si somDeamon encara no ha processat les imatges.
          if ( images.length !== this.currentProposta.NUMERO_IMATGES ) {
            setTimeout(() => {
              this.toastr.info(this.i18n('Encara no s\'han carregat totes les imatges de la proposta al sistema, passant a la següent proposta'));
              this.nextProposta();
              this.changeCurrent();
            }, 1500);
          } else {
            this.currentProposta.IMATGES = images;
            this.imagesLoaded = Promise.resolve(true);
          }
        });
    } else {
      this.propostaViewer.updateImatges(this.currentProposta.IMATGES);
    }
  }

  getPropostaActive(prop) {
    if (this.filter.proposta === prop) {
      return 'is-active';
    } else {
      return '';
    }
  }

  getTipusPropostaActive(tipusProp) {
    if (this.filter.tipusproposta == null) {
      return '';
    } else {
      if (this.filter.tipusproposta.toString() === tipusProp.toString()) {
        return 'active';
      } else {
        return '';
      }
    }
  }

  getCountryActive(pais) {
    if (this.filter.pais == null) {
      return '';
    } else {
      if (this.filter.pais.toString() === pais.toString()) {
        return 'active';
      } else {
        return '';
      }
    }
  }

  getPropostaPos() {
    return this.current + this.currentPage * this.pageSize + 1;
  }


  imageUrl(somId, suffix = '') {
    return this.imagesService.getImageUrl(somId, suffix);
  }



  getTipoPropostaCounters() {
    this.propostesService
      .getTipusCounters(this.filter)
      .subscribe(items => {
        let found = false;
        this.tipusCounters.forEach(tipus => (tipus.Count = 0));
        items.sort(function(a, b) { return a.Key > b.Key ? 1 : a.Key < b.Key ? -1 : 0; }).forEach(tipus => {
          const index = this.tipusCounters.findIndex(x => x.Key === tipus.Key);
          if (index >= 0) {
            this.tipusCounters[index].Count = tipus.Count;
          } else {
            this.tipusCounters.push(new KeyCounter(tipus.Key));
          }
          if (this.filter.tipusproposta === '-1' && items.length === 1 && tipus.Count > 0 && !this.selectedAll) {
             this.filter.tipusproposta = tipus.Key;
             this.tipoPropuestaCombo.selectManually(tipus.Key);
          }
          if (this.filter.tipusproposta !== null  && tipus.Key.toString() === this.filter.tipusproposta.toString()) {
            found = true;
          }
        });
        if (!found && this.tipusCounters.length > 0 && !this.selectedAll) {
          // this.filter.tipusproposta = this.tipusCounters[0].Key;
        }
        if (this.selectedAll) {
          this.selectedAll = false;
        }

        this.tiposValidosSource.next(this.getTiposValidos());
        this.reloadData();
      });
  }

  reloadData() {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.loadData();
      this.timeout = null;
    }, 100);

  }

  getPaisosCounters() {
    if (false) {
      /* this.propostesService
      .getPaisosCounters(this.filter)
      .subscribe(items => {
        this.paisosCounters[this.paisosCounters.length - 1].Count = 0;
        this.paisosCounters.forEach(tipus => (tipus.Count = 0));
        items.forEach(tipus => {
          const index = this.paisosCounters.findIndex(x => x.Key === tipus.Key);
          // if (index >= 0 && tipus.Key !== '') {
            this.paisosCounters[index].Count = tipus.Count;
          /* } else {
            this.paisosCounters[this.paisosCounters.length - 1].Count += tipus.Count;
          } */
        /*});
        this.paisosCounters.forEach(tipus => {
          if ((this.filter.pais === null && tipus.Count > 0)) {
            this.filter.pais = tipus.Key;
            // this.paisCombo.selectManually(this.filter.pais);
          } else {
            // this.paisCombo.selectManually(this.filter.pais);
          }
        });
        this.paisesValidosSource.next(this.getPaisesValidos());
        this.reloadData();
      });*/
    }
  }

  getTipusCounter(key: string) {
    const item = this.tipusCounters.find(x => x.Key === key.toString());
    if (item === null || item === undefined) {
      return 0;
    } else {
      return item.Count;
    }
  }

  getCountryCounter(key: string) {
    if (this.agruparPerPais) {
      const item = this.paisosCounters.find(x => x.Key === key.toString());
      if (item === null || item === undefined) {
        return 0;
      } else {
        return item.Count;
      }
    } else {
      return 0;
    }
  }

  veurePropostes(tipus) {
    this.router.navigateByUrl('/propostes/' + tipus);
  }

  veureLlistaPropostes() {
    this.router.navigateByUrl('/propostes-list');
  }

  canviMatricula() {
    const dlg = this.dialog.open(SingleEditDialogComponent, {
      closeOnNavigation: false,
      width: '70%',
      height: '80%',
      autoFocus: true,
      data: new SingleEditDialogData(this.i18n('Canvi de matrícula'), this.i18n('Matrícula del vehicle'), this.currentProposta.MATRICULA, /^[a-zA-Z0-9]*$/i, this.i18n('La matrícula només pot contenir números i lletres (sense espais ni símbols)!'))
    }).afterClosed().subscribe(matricula => {
      if (matricula) {
        this.propostesService.canviMatricula(this.currentProposta.SECURE_ID, matricula).subscribe(result => {
          this.currentProposta.MATRICULA = matricula;
          this.toastr.info(this.i18n('Matrícula modificada correctament'));
        });
      }
    });

  }

  // getLongitude(): number {
  //   let long = null;
  //   if (this.currentProposta !== undefined && this.currentProposta !== null) {
  //     if (this.currentProposta.LONGITUD !== null) {
  //     long = this.currentProposta.LONGITUD;
  //     }
  //   }
  //   if (long === null) {
  //     long = AppComponent.getInstitution().Longitud;
  //   }
  //   return long;
  // }

  // getLatitude(): number {
  //   let lat = null;
  //   if (this.currentProposta !== undefined && this.currentProposta !== null) {
  //     if (this.currentProposta.LATITUD !== null) {
  //       lat = this.currentProposta.LATITUD;
  //     }
  //   }
  //   if (lat === null) {
  //     lat = AppComponent.getInstitution().Latitud;
  //   }
  //   return lat;
  // }


  propostaValida() {
    let valid = true;
    this.validacioError.length = 0;
    if (!Reflection.isNull(this.currentProposta)) {
      if (this.validation.isEmpty(this.currentProposta.pais)) {
        valid = false;
        this.validacioError.push(this.i18n('Falta el país'));
      }
      if (this.validation.isEmpty(this.currentProposta.MATRICULA)) {
        valid = false;
        this.validacioError.push(this.i18n('Falta matrícula'));
      }
    }
    return valid;
  }

  getCountryName(key: string) {
    if (!this.agruparPerPais || Reflection.empty(key)) {
      return this.i18n('Altres');
    } else {
      const item = this.paisos.find(x => x.SIGLA === key);
      return item.PAINOMPAI;
    }
  }

  getCountryCode(key: string) {
    if (!this.agruparPerPais || Reflection.empty(key)) {
      return '';
    } else {
      const item = this.paisos.find(x => x.SIGLA === key);
      return item.PAISIGPAI.toLowerCase();
    }
  }

  getCurrentPropostaText() {
    return this.getPropostaPos() + ' ' + this.i18n('de') + ' ' + this.totalPropostes;
  }

  getPaisesValidos() {
    const paisesValidos = new Array<ChipListElement>();
    if (this.paisosCounters !== undefined) {
      this.paisosCounters.forEach(p => {
        paisesValidos.push(new ChipListElement(
          p.Key,
          this.getCountryName(p.Key),
          p.Count
         ));
      });
    }
    return paisesValidos;
  }

  paisSeleccionado(option: ChipListElement) {
    this.filter.pais = option.key;
    if (this.filter.tipusproposta !== null) {
      this.imagesLoaded = Promise.resolve(false);
      this.filter.tipusproposta = null;
      // this.tipoPropuestaCombo.resetSelecteds();
    }
    this.update(true);
  }

  getTiposValidos() {
    let countersTotals = 0;
    const tiposValidos = new Array<ChipListElement>();
    if (this.tipusPropostes !== undefined) {
      this.tipusPropostes.forEach(element => {
        const counter: number = this.getTipusCounter(element.ID);
        if (counter > 0) {
          tiposValidos.push(new ChipListElement(element.ID, element.NAME, counter));
          countersTotals += counter;
        }
      });
    }
    tiposValidos.push(new ChipListElement(this.CONST_ID_ALL, this.i18n('Tots'), countersTotals));
    return tiposValidos;
  }

  tipoSeleccionado(option: ChipListElement) {
    if (option !== undefined && option.key !== undefined) {
      if (option.key === this.CONST_ID_ALL) {
        this.filter.tipusproposta = null;
        this.selectedAll = true;
      } else {
        this.filter.tipusproposta = option.key;
      }
      this.update(true);
    }
  }

  changeOrdre() {
    if (this.ordre === 'desc') {
      this.ordre = 'asc';
    } else {
      this.ordre = 'desc';
    }
    this.loadData();
  }
}

