import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef, ChangeDetectionStrategy } from '@angular/core';
import { TimePickerIntl } from 'path-shared/services/form/timepicker-intl';
import { OwlDateTimeIntl } from 'ng-pick-datetime';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'HH:mm',
  fullPickerInput: 'HH:mm:ss',
  datePickerInput: 'HH:mm:ss',
  timePickerInput: 'HH:mm:ss',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-form-time-range-input',
  templateUrl: './time-range-input.component.html',
  styleUrls: ['./time-range-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: OwlDateTimeIntl, useClass: TimePickerIntl}
  ]
})
export class TimeRangeInputComponent implements OnInit {
  val: Date[];

  @Input() title: string;
  @Input() label1: string;
  @Input() label2: string;

  @Input() get value() {
    return this.val;
  } 
  set value(val) {
    this.val = val;
    setTimeout(() => {
      this.valueChange.emit(this.val);
    }, 10);
  }
  @Output() valueChange = new EventEmitter();
  @Input() class: string;
  type: string;
  @Input() id: string;
  @Input() disabled: boolean;
  @Input() initialValue = undefined;
  @Input() isReadOnly = false;

  constructor(public cr: ViewContainerRef) {
    this.val = new Array<Date>();
    this.type = 'timer';
  }

  ngOnInit() {
    let initialValueStr: String = this.initialValue;
    if (this.initialValue !== undefined && initialValueStr !== '') {
      if(typeof(this.initialValue) == 'string') {
        this.val = []
        this.val.push(new Date(initialValueStr.split(',')[0]));
        this.val.push(new Date(initialValueStr.split(',')[1]));
      }
      else this.val = this.initialValue;
    }
  }

  reset() {
    this.val = new Array<Date>();
  }

}
