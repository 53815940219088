import { SortDirection } from "@angular/material";
import { FilterData } from "path-shared/models/filter-data";

export class ConjuntAparellsFilter extends FilterData {
    id: string;
    descripcio: string;
    horaInici: Date;
    horaFi: Date;
  
    constructor(sortField: string, sortDir: SortDirection) {
      super(sortField, sortDir);
      this.id = '';
      this.descripcio = '';
      this.horaInici = null;
      this.horaFi = null;
  
      this.labels['id'] = this.i18n('Id');
      this.labels['descripcio'] = this.i18n('Descripció');
      this.labels['horaInici'] = this.i18n('Hora inici');
      this.labels['horaFi'] = this.i18n('Hora fi');
      
    }
}