import { Aparell } from '../models/aparell.model';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { AparellsFilterService } from './aparells-filter-service';
import { AparellFilter } from '../models/aparell-filter';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { HttpParams } from '@angular/common/http';
import { DeteccioMatriculesFilterService } from 'app/sections/ciutat/deteccio-matricules/services/deteccio-matricules-filter.service';
import { DeteccioMatriculaFilter } from 'app/sections/ciutat/deteccio-matricules/models/deteccio-matricula-filter';
import { Sector } from '../models/sector';
import { ConjuntAparells } from '../models/conjunt-aparells.model';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class AparellService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  getAllCombo(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAparellsCombo'], null);
    }
  }

  getListasBlancas(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAparellsListasBlancas'], null);
    }
  }

  setAparell(aparell: Aparell): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetAparell'], aparell);
    }
  }

  removeAparell(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteAparell'].replace('{id}', id), null);
    }
  }

  setConjuntAparells(conjunt: ConjuntAparells): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetConjuntAparells'], conjunt);
    }
  }

  removeConjuntAparells(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteConjuntAparells'].replace('{id}', id), null);
    }
  }

  addRTAI(RTAI) {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlRTAI'], RTAI);
    }
  }

  addRTAC(RTAC) {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlRTAC'], RTAC);
    }
  }

  removeRTAI(RTAI): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new HttpParams()
        .append('infraccio', RTAI.infraccio)
        .append('aparellID', RTAI.aparellID)
        .append('PDA', RTAI.PDA);
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlRTAI'], params);
    }
  }

  removeRTAC(RTAC): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new HttpParams()
        .append('idCarrer', RTAC.idCarrer)
        .append('aparellID', RTAC.aparellID)
        .append('PDA', RTAC.PDA);
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlRTAC'], params);
    }
  }

  getAparell(id: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new HttpParams().append('secure_id', id.replace('/', '$'));
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAparell'], params);
    }
  }

  getAparellTipusRadar(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAparellTipusRadar'], null);
    }
  }

  saveSector(sector: Sector) {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSector'], sector);
    }
  }

  deleteSector(id: number) {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlSector'] + '/' + id, null);
    }
  }

  generateExcel(filter: AparellFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new AparellsFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlAparellExcel'],  params );
    }
  }


}
