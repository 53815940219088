import { OnInit, EventEmitter, ViewContainerRef } from '@angular/core';
export var MY_CUSTOM_FORMATS = {
    parseInput: 'HH:mm',
    fullPickerInput: 'HH:mm:ss',
    datePickerInput: 'HH:mm:ss',
    timePickerInput: 'HH:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};
var TimeRangeInputComponent = /** @class */ (function () {
    function TimeRangeInputComponent(cr) {
        this.cr = cr;
        this.valueChange = new EventEmitter();
        this.initialValue = undefined;
        this.isReadOnly = false;
        this.val = new Array();
        this.type = 'timer';
    }
    Object.defineProperty(TimeRangeInputComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            var _this = this;
            this.val = val;
            setTimeout(function () {
                _this.valueChange.emit(_this.val);
            }, 10);
        },
        enumerable: true,
        configurable: true
    });
    TimeRangeInputComponent.prototype.ngOnInit = function () {
        var initialValueStr = this.initialValue;
        if (this.initialValue !== undefined && initialValueStr !== '') {
            if (typeof (this.initialValue) == 'string') {
                this.val = [];
                this.val.push(new Date(initialValueStr.split(',')[0]));
                this.val.push(new Date(initialValueStr.split(',')[1]));
            }
            else
                this.val = this.initialValue;
        }
    };
    TimeRangeInputComponent.prototype.reset = function () {
        this.val = new Array();
    };
    return TimeRangeInputComponent;
}());
export { TimeRangeInputComponent };
