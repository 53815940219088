import { Observable } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-qr-viewer',
  templateUrl: './qr-viewer.component.html',
  styleUrls: ['./qr-viewer.component.scss']
})
export class QrViewerComponent implements OnInit {
  public qrData: string = null;
  public errorCorrectionLevel: string = null;
  public valor: string = null;
  public label: string='QR';
  public width: number = 256;
  nomImatge:string='qr';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any) { 
//       let encrypted  =// shajs('sha256').update(data['qrData'].toString()).digest('hex');
// const encoder = new TextEncoder();
// const encrypted = encoder.encode(data['qrData'].toString());
       this.valor = data['qrData'].toString();
       
      if(data['fileName'] !== undefined && data['fileName'] !== null && data['fileName'] !== ''){
        this.nomImatge = data['fileName'];
        this.label = this.nomImatge;
      }
    }

  async ngOnInit() {

    this.qrData = this.digestMessage(this.valor);
    console.log(this.qrData);
  }
   digestMessage(message) {
    // const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
    // const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
    // const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
    // const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
    const hashHex = btoa(message);
    return hashHex;
  }
  downolad(qrImage){
    const parentElement = qrImage.el.nativeElement.querySelector("img").src;

    let blobData = this.convertBase64ToBlob(parentElement);

    let wndNavigator: any = window.navigator;
    // saves as image
    if (window.navigator && wndNavigator.msSaveOrOpenBlob) { //IE
      wndNavigator.msSaveOrOpenBlob(blobData, this.nomImatge);
    } else { // chrome
      const blob = new Blob([blobData], { type: "image/png" });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      const link = document.createElement('a');
      link.href = url;
      link.download = this.nomImatge;
      link.click();
    }
  }


  private convertBase64ToBlob(Base64Image: any) {
    const parts = Base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: imageType });
  }
}
