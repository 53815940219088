<div class="form-group">
  <mat-form-field ><!--floatPlaceholder="never"-->
    <input type="text" #input [placeholder]="this.label"  aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto" [required]="this.required" 
    (keyup.enter)="onEnter()" [readonly]="this.isReadOnly || this.disableInput" (focusout)="focusOut()" [style.cursor]="this.disableInput?'default':'auto'">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getDisplay()" [class]="this.getClass()" panelWidth="auto"  (optionSelected)="desFocus()" [disabled]="this.isReadOnly">
      <mat-option class="multiline-mat-option" *ngFor="let option of filteredOptions | async" [value]="option">
        {{this.getText(option)}}
      </mat-option>
    </mat-autocomplete>
    <button *ngIf="!this.isReadOnly && !this.disableInput" tabindex="-1" (click)="togglePanel($event)" class="btn-xs btn-primary-outline"><i class="fa fa-caret-down"></i></button>
  </mat-form-field>
  <div [hidden]="this.valid()" class="text-danger alert-field-errors">
      <div [hidden]="!this.errorRequired()" i18n>Aquest camp és obligatori!</div>
  </div>
</div>