import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { AparellService } from '../../services/aparells-service.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { FormService } from 'path-shared/services/form/form.service';
import { ConjuntAparells } from '../../models/conjunt-aparells.model';
import { Aparell } from '../../models/aparell.model';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { Builder } from 'path-shared/models/builder';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-conjunts-aparells-editor',
  templateUrl: './conjunts-aparells-editor.component.html',
  styleUrls: ['./conjunts-aparells-editor.component.scss']
})
export class ConjuntsAparellsEditorComponent implements OnInit {

  dialogData: ComponentDialog;
  closeButtonText: string;
  clearFrom = false;
  
  @ViewChild('mainForm') private mainForm: ElementRef;
  @Input() isReadOnly: boolean;

  public rangHores: Date[];
  public conjunt: ConjuntAparells;
  public aparells = Array<FormMultiSelect>();
  public selectedAparells = Array<FormMultiSelect>();

  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private aparellsService: AparellService,
    public i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<ConjuntsAparellsEditorComponent>,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.rangHores = [];
    if (data.conjunt === null) {
      this.conjunt = Builder.newConjunt();
    }
    else {
      this.conjunt = data.conjunt;
      this.conjunt.cameraInici = this.conjunt.cameraInici == null ? Builder.newAparell() : this.conjunt.cameraInici,
      this.conjunt.cameraDesti = this.conjunt.cameraDesti == null ? Builder.newAparell() : this.conjunt.cameraDesti,
      this.conjunt.aparells = this.conjunt.aparells == null ? new Array<Aparell>() : this.conjunt.aparells

      if (this.conjunt.horaInici !== null || this.conjunt.horaFi !== null) {
        let tmpDate = new Date(1900,1,1,parseInt(this.conjunt.horaInici.substring(0,2)), parseInt(this.conjunt.horaInici.substring(3,5)),0)
        this.rangHores.push(tmpDate);
        tmpDate = new Date(1900,1,1,parseInt(this.conjunt.horaFi.substring(0,2)), parseInt(this.conjunt.horaFi.substring(3,5)),0)
        this.rangHores.push(tmpDate);
      }
    }
    this.dialogData = data.componentDialog;
    this.isReadOnly = true;

    if (this.dialogData.isReadOnly) {
      this.closeButtonText = this.i18n('Tancar');
    } else {
      this.closeButtonText = this.i18n('Llista');
    }
  }

  ngOnInit() {
    this.aparellsService.getAllCombo().subscribe(result => {
      this.aparells = [];
      result.forEach(element => {
        this.aparells.push(new FormMultiSelect(element.id, element.descripcion));
      })
    });
    if (this.conjunt.aparells.length !== 0) {
      this.conjunt.aparells.forEach(x => this.selectedAparells.push(new FormMultiSelect(parseInt(x.id), x.descripcion)));
    }
  }

  save() {
    this.conjunt.horaInici = this.mainForm.nativeElement[2].value;
    this.conjunt.horaFi = this.mainForm.nativeElement[3].value;
    if (this.formService.allFieldsValid(this.mainForm) && (this.conjunt.horaInici != '' && this.conjunt.horaFi != '') || (this.conjunt.horaInici == '' && this.conjunt.horaFi == '')) {
      this.conjunt.aparells = [];
      this.selectedAparells.forEach(y => 
        this.conjunt.aparells.push(Builder.newAparellSimple(y.item_id.toString()))
      )
      this.aparellsService.setConjuntAparells(this.conjunt).subscribe(result => {
        
        this.toastr.success(this.i18n('Aparell guardat correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar l\'aparell?'))
      .then((confirmed) => {
        if (confirmed) {
          this.aparellsService.removeConjuntAparells(this.conjunt.id).subscribe(result => {
            this.toastr.success(this.i18n('Aparell esborrat correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }

}
