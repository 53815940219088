import { Injectable } from "@angular/core";
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";
import { ConjuntAparellsFilter } from "../models/conjunt-aparells.filter";
import { HttpParams } from "@angular/common/http";
import { Reflection } from "path-shared/services/reflection/reflection";

const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class ConjuntsAparellsFilterService extends CustomFilterService {
  public createFilterParams(filter: ConjuntAparellsFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('id', filter.id)
    .append('descripcio', filter.descripcio)
    .append('horaInici', Reflection.empty(filter.horaInici) ? null : filter.horaInici.toDateString())
    .append('horaFi', Reflection.empty(filter.horaFi) ? null : filter.horaFi.toDateString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}