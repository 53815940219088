<!--<div class="col-sm-13">-->
  <!--<div class="row">-->
  <div>
    <div *ngIf="this.titulo != undefined">{{this.titulo}}</div>
    <!--<div [ngClass]="this.titulo !== undefined?'col-sm-9':'col-sm-12'">-->
    <ng-multiselect-dropdown style="min-width: 10vh;"
      [placeholder]="this.tituloMultiSelect"
      [disabled]="this.disabled"
      [data]="this.data"
      [(ngModel)]="this.selectedValues"
      [settings]="this.dropdownSettings"
      (onSelect)="this.onItemSelect($event)"
      (onSelectAll)="this.onSelectAll($event)">
    </ng-multiselect-dropdown>
  </div>
    <!--</div>-->
  <!--</div>-->
<!--</div>-->
