import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AparellFilter } from '../../models/aparell-filter';
import { ConjuntAparellsFilter } from '../../models/conjunt-aparells.filter';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-conjunts-aparells-filter',
  templateUrl: './conjunts-aparells-filter.component.html',
  styleUrls: ['./conjunts-aparells-filter.component.scss']
})
export class ConjuntsAparellsFilterComponent implements OnInit {

  subscription: Subscription;
  filter: ConjuntAparellsFilter;

  @Output() notify: EventEmitter<ConjuntAparellsFilter> = new EventEmitter<ConjuntAparellsFilter>();

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    public thisDialogRef: MatDialogRef<ConjuntsAparellsFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.filter = data.filter;
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
      this.filter.updated();
      this.notify.emit(this.filter);
      this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
