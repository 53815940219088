<!-- HEADER -->
<app-dialog-header label="Conjunt d'aparells" i18n-label></app-dialog-header>
<!-- TOOLBAR -->
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green"
      icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Aparells) || !this.dialogData.isReadOnly"
      [disabled]="!this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save"
      [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Aparells) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt"
      [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Aparells) || this.dialogData.isReadOnly"></app-tool-button>
</app-toolbar>
<form #mainForm>
  <div style="display: flex; flex-direction: row; justify-content: space-around;">
    <div style="width: 45%;">
      <app-form-text-edit i18n-label label="Descripció"
        [(value)]="this.conjunt.descripcio" maxLength="50" required></app-form-text-edit>
      <app-form-time-range-input #timeRangeInput value="{{this.rangHores}}" initialValue="{{this.rangHores}}" title="Hores" label1="Inici" label2="Fi"></app-form-time-range-input>
    </div>
  
    <div style="width: 45%;">
      <div style="display: flex; flex-direction: row; margin-bottom: 1vh; padding: 1vh;">
        <app-form-check-edit i18n-label label="TRÀNSIT"
          [(value)]="this.conjunt.esTransit"></app-form-check-edit>
          <mat-icon class="col-1 info-icon" i18n-matTooltip 
          matTooltip="Si és un conjunt de trànsit esculli les càmeres d'inici i destí, en cas contrari, els ràdars." matTooltipClass="info-tooltip">info</mat-icon>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-around; background-color: white; margin-bottom: 2vh; padding: 1vh;">
        <app-form-combo-edit [items]="this.aparells" i18n-label label="Càmera inici"
          [(value)]="this.conjunt.cameraInici.id" textField="item_text" valueField="item_id"
          [disableInput]="!this.conjunt.esTransit"></app-form-combo-edit>
        <app-form-combo-edit [items]="this.aparells" i18n-label label="Càmera destí"
          [(value)]="this.conjunt.cameraDesti.id" textField="item_text" valueField="item_id"
          [disableInput]="!this.conjunt.esTransit"></app-form-combo-edit>
      </div>
      <div  style="background-color: white; padding: 1vh;">
        <app-form-multi-select titulo="Aparells" [data]="aparells"
          [(selectedValues)]="this.selectedAparells" i18n-titulo 
          tituloMultiSelect="Selecciona una o més" i18n-tituloMultiSelect 
          [disabled]="this.conjunt.esTransit"></app-form-multi-select>
      </div>
    </div>
  </div>
</form>